
  export default {
    name: 'JobsNavigation',
    data() {
      return {
        menuItems: [
          // {
          //   icon: 'fal fa-hand-holding-heart',
          //   title: 'Job-Empfehlungen',
          //   count: '11',
          //   route: {
          //     name: 'jobs',
          //   },
          // },
          {
            icon: 'fal fa-edit',
            title: this.$t('page.myApplications'),
            count: '20',
            route: {
              name: 'myApplications',
            },
          },
          {
            icon: 'fal fa-heart',
            title: this.$t('page.myFavorites'),
            count: '5',
            route: {
              name: 'myFavorites',
            },
          },
          {
            icon: 'fal fa-money-bill',
            title: this.$t('page.myByllAccount'),
            count: '5',
            route: {
              name: 'myByllAccount',
            },
          },
        ],
      };
    },
    created() {
      if (this.$featureToggle.toggles['rating']) {
        this.menuItems.splice(2, 0, {
          icon: 'fal fa-star',
          title: this.$t('page.myRatings'),
          count: '20',
          route: {
            name: 'myRatings',
          },
        });
      }
      if (this.$featureToggle.toggles['job_alerts']) {
        this.menuItems.splice(
          this.$featureToggle.toggles['rating'] ? 3 : 2,
          0,
          {
            icon: 'fal fa-bell',
            title: this.$t('page.myJobAlerts'),
            count: '5',
            route: {
              name: 'myJobAlerts',
            },
          }
        );
      }
    },
  };
